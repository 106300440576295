import { Controller } from "@hotwired/stimulus"

// Refresh specific logic
export default class extends Controller {
  static values = { reloadLocation: String }


  connect () {
    const refreshInterval = this.element.dataset.refreshInterval || 3

    this.intervalId = null
    this.userInputTimer = null
    this.events = ["keydown", "click", "touchstart"]

    this.setupUserActivityListeners(refreshInterval)
  }

  disconnect () {
    clearInterval(this.intervalId)
    clearTimeout(this.userInputTimer)
    this.removeUserActivityListeners()
  }

  reloadPage () {
    if (this.element.dataset.refreshEnabled === "true") {
      if (this.reloadLocationValue) {
        window.location.href = this.reloadLocationValue
      } else {
        window.location.reload()
      }
    } else {
    }
  }

  setupUserActivityListeners (timeout) {
    // Listen for user input
    this.events.forEach((event) => {
      document.addEventListener(event, () => {
        this.resetUserInputTimer(timeout)
      })
    })

    this.resetUserInputTimer(timeout)
  }

  removeUserActivityListeners () {
    this.events.forEach((event) => {
      document.removeEventListener(event, this.resetUserInputTimer)
    })
  }

  resetUserInputTimer (timeout) {
    clearTimeout(this.userInputTimer)
    this.userInputTimer = setTimeout(() => {
      this.reloadPage()
    }, timeout * 1000)
  }
}
